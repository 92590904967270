<template>
  <div class="mb-12">
    <div class="mb-3 d-flex">
      <h2 class="mr-3" v-text="titleSection" />
      <global-tooltip :text="textTooltipTitleSection" />
    </div>
    <image-field
      :value="valueImageLink"
      class="mb-6"
      :width="width" :height="height" :suffix="suffix"
      can-be-deleted @input="$emit('inputImage', $event)"
    />
    <div class="d-flex align-baseline">
      <v-text-field
        :value="valueSponsoredLink"
        class="mr-3" outlined dense
        :label="$t('project.sponsorizationProject.sponsoredLink')"
        @input="$emit('inputSponsoredLink', $event)"
      />
      <div>
        <global-tooltip :text="$t('project.sponsorizationProject.sponsoredLinkTooltip')" />
      </div>
    </div>
  </div>
</template>
<script>
import i18n from '@/plugins/i18n'

export default {
  name: 'SponsorizationProjectBannerData',
  components: {
    GlobalTooltip: () => import('@/components/GlobalTooltip'),
    ImageField: () => import('@/components/formFields/ImageField'),
  },
  props: {
    titleSection: { type: String, required: true },
    textTooltipTitleSection: {
      type: String,
      default() {
        return i18n.t('common.tooltipImageSizes', {
          proportion: '16:9',
          width: '656px',
          height: '220px',
        })
      },
    },
    width: { type: Number, default: 656 },
    height: { type: Number, default: 220 },
    suffix: { type: String, default: null },
    valueSponsoredLink: { type: String, default: null },
    valueImageLink: { type: String, default: null },
  },
}
</script>
